import React, { useState, useEffect } from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './Artists.css'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import axios from 'axios'
import VenueTable from './VenueTable';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAuth } from './Auth/AuthContext';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function Venues() {
  const { currentUser} = useAuth()

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [artistFee, setArtistFee] = useState("");
  const [venueFee, setVenueFee] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [newItem, setNewItem] = useState("")
  const [equipment, setEquipment] = useState([
    { key: 0, label: 'PA' },
    { key: 1, label: 'Mixer' },
    { key: 2, label: 'Microphone' },
    { key: 3, label: 'Mic Stand' },
    { key: 4, label: 'Instruments' },
    { key: 5, label: 'Cables' },
  ]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [venueReq, setVenueReq] = useState("");
  const [setTimes, setSetTimes] = useState([])
  const [paymentType, setPaymentType] = useState("")
  const [venues, setVenues] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    getVenues()
  },[])

  const getVenues = () =>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/venue/get-venues`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{setVenues(res.data);setLoading(false)})
    .catch((e)=>{console.log(e);setLoading(false)})

  }

  const handleDelete = (itemToDelete) => () => {
    setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addSet = () =>{
    if(!from || !to){
      toast.error("Please add from and to time")
    } else {
      let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
      setSetTimes([...setTimes, {key,from, to}])
      setTo("");setFrom("")
    }
  }

  const handleSave = () =>{
    if(!name || !address || !artistFee || !venueFee || ! setTimes || !paymentType){
      toast.error("Please add required fields")
    } else {
      axios.post(`${process.env.REACT_APP_API}/venue/add-new`,
      {venueName: name, xeroName: "", address, setTimes, equipment, contactName, contactEmail: email, artistFee,venueReq, venueFee, paymentType, img: 'https://firebasestorage.googleapis.com/v0/b/musicgoferproductionadmin.appspot.com/o/venue.png?alt=media&token=7323b574-ffe4-450a-948f-108953ff2a2f'}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=> {
        toast.success(res.data)
        handleClose();
        getVenues();
        setName("");setAddress("");setArtistFee("");setVenueFee("");setContactName(""); setEmail("");setSetTimes([]); setEquipment([
          { key: 0, label: 'PA' },
          { key: 1, label: 'Mixer' },
          { key: 2, label: 'Microphone' },
          { key: 3, label: 'Mic Stand' },
          { key: 4, label: 'Instruments' },
          { key: 5, label: 'Cables' },
        ])
            })
      .catch((e)=>console.log(e))
    }
  }

  const addItem = (e)=>{
    if(e.key === 'Enter'){
      console.log(newItem)
      setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
      setNewItem("")
    }
  }

  const removeSet = (set) =>{
    setSetTimes((item) => item.filter((item) => item.key !== set.key));
  } 

  return (
    <main id='artist-container'> 
      <Fab sx={{backgroundColor: '#3496d1'}} aria-label="add" onClick={handleOpen}>
        <AddIcon sx={{color: 'white'}} />
      </Fab>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Venue
          </Typography>
          
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Venue Name' value={name} onChange={(e)=>setName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Address' multiline rows={2} value={address} onChange={(e)=>setAddress(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Artist Fee'  InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Venue Fee' InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }} value={venueFee} onChange={(e)=>setVenueFee(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Contact Name' value={contactName} onChange={(e)=>setContactName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Contact Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Venue Requirements' value={venueReq} onChange={(e)=>setVenueReq(e.target.value)}/>

          <label>From</label>
          <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
          <label>To</label>
          <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
         <button onClick={addSet} style={{backgroundColor: '#3496d1', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
         {setTimes.map((set, i)=>{
              return(
                <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <h4 style={{color: '#395076'}}>Set {i+1}</h4>
                  <p style={{color: '#6e767b'}}>{set.from} - {set.to}</p>
                  <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                </div>
              ) 
            })}
          <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
            {equipment.map((data) => {
              let icon;
              return (
                <ListItem key={data.key}>
                  <Chip icon={icon} label={data.label} onDelete={handleDelete(data)} />
                </ListItem>
              );
            })}

            <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>
          </Paper>

          <FormControl fullWidth >
            <FormLabel id="demo-row-radio-buttons-group-label">Payment Method</FormLabel>
            <RadioGroup value={paymentType} onChange={(e)=>setPaymentType(e.target.value)}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{margin:'10px auto'}}
            >
              <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
              <FormControlLabel value="Invoice" control={<Radio />} label="Invoice" />
          
            </RadioGroup>
          </FormControl>

          <Button sx={{backgroundColor: '#3496d1'}} variant='contained' onClick={handleSave}>Save</Button>
        </Box>
      </Modal>

      <div>

        <h3>Venues</h3>
        {loading? 
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>:
        venues.length < 1 ?
        <p>No venues added</p> :
        <VenueTable venues={venues} getVenues={getVenues}/>}

      </div>

      
    </main>
  )
}
const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  overflow:'scroll',
};
