import React, { useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { toast} from 'react-toastify'
import axios from 'axios'
import { adminStorage } from './Firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAuth} from './Auth/AuthContext'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function VenueView(
    {img, getVenues, setImg, name, setName, xeroName, setXeroName, address, setAddress, setTimes, setSetTimes, equipment, setEquipment, contactName, setContactName, contactEmail, setContactEmail,artistFee, setArtistFee, venueFee, setVenueFee, id, open, handleClose, venueReq, setVenueReq, paymentType, setPaymentType}
    ) {

      const { currentUser} = useAuth()

    const [newItem, setNewItem] = useState("")
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");

    const handleUpdate = () =>{
        if(!name ){
            toast.error("Please add name")
        } else {
         axios.post(`${process.env.REACT_APP_API}/venue/update`, {_id: id, 
          payload:{venueName: name, xeroName, address, setTimes, equipment, contactName, contactEmail, artistFee, venueFee, img, venueReq, paymentType}
        }, {headers: {'AuthToken': currentUser.accessToken}})
         .then(()=>{
             toast.success("Successfully updated")
             handleClose()
             getVenues()
         })
         .catch((e)=>console.log(e))
        }
     }

     const handleChipDelete = (itemToDelete) => () => {
        setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
      };
 
 
     const handleDelete = () =>{
         if(window.confirm("Are you sure you want to delete venue?")){
             axios.post(`${process.env.REACT_APP_API}/venue/delete`, {_id: id}, {headers: {'AuthToken': currentUser.accessToken}})
             .then((res)=>{
                 toast.success(res.data)
                 handleClose()
                 getVenues()
             })
             .catch((e)=>console.log(e))
         }
     }
 
     const imgRef = ref(adminStorage, `${name}/${id}`);
     const uploadImg = (file) =>{
         uploadBytes(imgRef, file).then((snapshot) => {
 
             getDownloadURL(imgRef)
             .then((url) => {
                 setImg(url)
             })
             .catch((error) => {
                 console.log(error)
             });
         })
     }

     const addItem = (e)=>{
      if(e.key === 'Enter'){
        console.log(newItem)
        setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
        setNewItem("")
      }
    }

    const removeSet = (set) =>{
      setSetTimes((item) => item.filter((item) => item.key !== set.key));
    } 

    const addSet = () =>{
      if(!from || !to){
        toast.error("Please add from and to time")
      } else {
        let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
        setSetTimes([...setTimes, {key,from, to}])
        setTo("");setFrom("")
      }
    }

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>

            <img src={img} alt={name} style={{width: '100px', height: '100px', margin: '10px auto', borderRadius: '50px', display: 'block', objectFit: 'cover', objectPosition:'center'}}/>
            <input type='file' onChange={(e)=>uploadImg(e.target.files[0])}/>
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Name' value={name} onChange={(e)=>setName(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Xero Name' value={xeroName} onChange={(e)=>setXeroName(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Address' value={address} onChange={(e)=>setAddress(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Artist Fee' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Fee' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}} value={venueFee} onChange={(e)=>setVenueFee(e.target.value)} />

            <TextField sx={{my:1}} variant='outlined' fullWidth label='Contact Name'  value={contactName} onChange={(e)=>setContactName(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Contact Email' value={contactEmail} onChange={(e)=>setContactEmail(e.target.value)} />
            <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Requirements' value={venueReq} onChange={(e)=>setVenueReq(e.target.value)} />

            <label>From</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
            <label>To</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
            <button onClick={addSet} style={{backgroundColor: '#395076', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
              
            {setTimes.map((set, i)=>{
              return(
                <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <h4 style={{color: '#395076'}}>Set {i+1}</h4>
                  <p style={{color: '#6e767b'}}>{set.from} - {set.to}</p>
                  <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                </div>
              ) 
            })}

            <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
            {equipment.map((data) => {
              let icon;
              return (
                <ListItem key={data.key}>
                  <Chip icon={icon} label={data.label} onDelete={handleChipDelete(data)} />
                </ListItem>
              );
            })}
                  <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>
            </Paper>

            <FormControl fullWidth >
              <FormLabel id="demo-row-radio-buttons-group-label">Payment Method</FormLabel>
              <RadioGroup value={paymentType} onChange={(e)=>setPaymentType(e.target.value)}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{margin:'10px auto'}}
              >
                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                <FormControlLabel value="Invoice" control={<Radio />} label="Invoice" />
            
              </RadioGroup>
            </FormControl>
         
            <Button sx={{backgroundColor: '#395076', mr:1}} variant='contained' onClick={handleUpdate}>Update</Button>
            <Button color="error" sx={{ml:1}} variant='contained' 
            onClick={handleDelete}>Delete</Button>


    </Box>
  </Modal>
  )
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '95%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    overflow:'scroll',
  };