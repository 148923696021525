import React, { useState, useEffect, useRef} from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import axios from 'axios'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import './Bookings.css'
import { toast} from 'react-toastify'
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment-timezone'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RRule } from 'rrule'
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAuth} from './Auth/AuthContext'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';

export default function Bookings() {
    const [type, setType] = useState("One-Off")
    const [date, setDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [venue, setVenue] = useState("")
    const [venueName, setVenueName] = useState("")
    const [xeroName, setXeroName] = useState("")
    const [venueImg, setVenueImg] = useState("")
    const [address, setAddress] = useState("")
    const [equipment, setEquipment] = useState([])
    const [setTimes, setSetTimes] = useState([])
    const [artistFee, setArtistFee] = useState("")
    const [venueFee, setVenueFee] = useState("")
    const [venueReq, setVenueReq] = useState("")
    const [notes, setNotes] = useState("")
    const [newItem, setNewItem] = useState("")
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [weekdays, setWeekdays] = useState([]);
    const [eventType, setEventType] = useState("")
    const [drives, setDrives] = useState(false);
    const [pa, setPa] = useState(false);
    const [decks, setDecks] = useState(false);
    const [paymentType, setPaymentType] = useState("");

    const { currentUser } = useAuth()

    //Select state
    const [venues, setVenues] = useState([])

    useEffect(()=>{
        getVenues()
    },[])

    useEffect( ()=>{
        if(venue){
        let info = getInfo(venue)
        setVenueImg(info[0].img); setXeroName(info[0].xeroName); setVenueReq(info[0].venueReq)
        setEquipment(info[0].equipment);setVenueName(info[0].venueName);setSetTimes(info[0].setTimes);setArtistFee(info[0].artistFee);setVenueFee(info[0].venueFee);setAddress(info[0].address)
        setPaymentType(info[0].paymentType)
        }
    },[venue])

    const handleDelete = (itemToDelete) => () => {
        setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
      };

    const getVenues = () =>{
        axios.get(`${process.env.REACT_APP_API}/venue/get-venues`, {headers: {'AuthToken': currentUser.accessToken}})
        .then((res)=>{
            setVenues(res.data)
        })
        .catch((e)=>console.log(e))
    }

    const clearData =()=>{
        setEquipment([]);setVenueName("");setXeroName("");setSetTimes([]);setArtistFee("");setVenueFee("");setVenueReq(""); setVenue(""); setDate("");setNotes("");setAddress(""); setEventType("");setPaymentType("")

    }

    const getInfo = (id) =>{
       let info = venues.filter(choice=> choice._id === id )
       return info
    }

    const createOneOff = () =>{
        if(!venue || !artistFee || !venueFee || !date ){
            toast.error("Please add all required fields")
        } else{
            let payload = { start: `${moment(`${date}T${setTimes[0].from}`).utc().format()}`, eventDate: moment(`${date}T${setTimes[0].from}`).utc().format(), dateStr: moment(date).format('YYYY-MM-DD'), title: venueName, xeroName, venueImg,venueReq, paymentType, venueId: venue, venueAddress: address, equipment, eventType, setTimes, artistFee, venueFee, notes, status: 'Unassigned', display: 'block', backgroundColor:'#CB4335', borderColor: '#CB4335', artistId: "", artistName:"", applied: [], drives, pa, decks}

            axios.post(`${process.env.REACT_APP_API}/event/new-event`, payload, {headers: {'AuthToken': currentUser.accessToken}})
            .then(()=>{ clearData();
                toast.success('Successfully created')})
            .catch((e)=>toast.error("Oops, something went wrong"))
        }
    }

    const createRecurring = () =>{
       if(!startDate || !endDate || !setTimes){
            toast.error("Add all required fields")
       } else {
        const rule = new RRule({
            freq: RRule.WEEKLY,
            //Get generic date then add the time on event creation
            dtstart: moment(`${startDate}T12:00`).toDate() ,
            until: moment(`${endDate}T14:00`).toDate(),
            count: 30,
            interval: 1,
            byweekday: weekdays,
            tzid: "UTC"
          })
          console.log(rule.all())

          rule.all().map((date)=>{
            let time = setTimes[0].from;
            let newDate = `${moment(date).format("YYYY-MM-DD")}T${time}`
            let payload = { start: moment(newDate).utc().format(), eventDate: newDate, dateStr: moment(date).format('YYYY-MM-DD'), title: venueName, xeroName, venueId: venue,venueImg, venueReq, paymentType, venueAddress: address, equipment,eventType, setTimes, artistFee, venueFee, notes, status: 'Unassigned', display: 'block', backgroundColor:'#CB4335', borderColor: '#CB4335', artistId: "", artistName:"", applied: [], drives, pa, decks}

            axios.post(`${process.env.REACT_APP_API}/event/new-event`, payload, {headers: {'AuthToken': currentUser.accessToken}})
            .then(()=>{ 
                console.log('Successfully created')})
            .catch((e)=>toast.error("Oops, something went wrong"))

          })
          
          clearData();
          toast.success("Events Created")
       }
    }

    const addItem = (e)=>{
        if(e.key === 'Enter'){
          console.log(newItem)
          setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
          setNewItem("")
        }
      }

      const removeSet = (set) =>{
        setSetTimes((item) => item.filter((item) => item.key !== set.key));
      } 
  
      const addSet = () =>{
        if(!from || !to){
          toast.error("Please add from and to time")
        } else {
          let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
          setSetTimes([...setTimes, {key,from, to}])
          setTo("");setFrom("")
        }
      }

      const handleDays = (e) =>{
        let value = e.target.value;

        if(weekdays.includes(value)){
            setWeekdays((day) => day.filter((day) => day !== value));
        } else {
            setWeekdays([...weekdays, value])
        }
      }

  return (
    <main id='booking-container'>
    {setTimes[0] && console.log(moment(`${date}T${setTimes[0].from}`).utc().format())}

        <ToggleButtonGroup sx={{my: 3}}
        color="primary"
        value={type}
        exclusive
        onChange={(e)=>setType(e.target.value)}
        aria-label="Platform"
        >
            <ToggleButton value="One-Off">One-Off</ToggleButton>
            <ToggleButton value="Recurring">Recurring</ToggleButton>
        </ToggleButtonGroup>
        <FormControl sx={{mb:2}}>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="eventType" value={eventType} onChange={(e)=>setEventType(e.target.value)}>
            <FormControlLabel value="Solo" control={<Radio />} label="Solo" />
            <FormControlLabel value="Duo" control={<Radio />} label="Duo" />
            <FormControlLabel value="DJ" control={<Radio />} label="DJ" />
            <FormControlLabel value="Band" control={<Radio />} label="Band" />
          </RadioGroup>
        </FormControl>
        <FormGroup row sx={{mb:2}}>
            <FormControlLabel control={<Switch checked={drives} onChange={()=> setDrives(!drives)} />} label="Drivers Only"labelPlacement="start" />
            <FormControlLabel control={<Switch checked={pa} onChange={()=> setPa(!pa)} />} label="Requires PA" labelPlacement="start"/>
            <FormControlLabel control={<Switch checked={decks} onChange={()=> setDecks(!decks)} />} label="Requires Decks" labelPlacement="start"/>
        </FormGroup>


       <Box sx={{ minWidth: 120, maxWidth: 700, margin: '0 auto', textAlign: 'center' }}>

        {type === 'One-Off' ?
        <div style={{display: 'flex', flexDirection: 'column', flexWrap:'wrap', alignItems: 'center'}}> 
            <label className='date-label' >Event Date</label>
            <input className='booking-date' type='date' value={date} onChange={(e)=>setDate(e.target.value)}/>
        </div>
        :
        <div> 
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap:'wrap'}}>
                <label className='date-label' >Date From</label>
                <input className='booking-date' type='date' value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>
            
                <label className='date-label' >Date To</label>
                <input className='booking-date' type='date' value={endDate} onChange={(e)=>setEndDate(e.target.value)}/>
            </div>
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    <FormControlLabel value={RRule.MO} control={<Checkbox />} label="M" labelPlacement="top" onChange={handleDays} />
                    <FormControlLabel value={RRule.TU} control={<Checkbox />} label="T" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.WE} control={<Checkbox />} label="W" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.TH} control={<Checkbox />} label="T" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.FR} control={<Checkbox />} label="F" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.SA} control={<Checkbox />} label="S" labelPlacement="top" onChange={handleDays}/>
                    <FormControlLabel value={RRule.SU} control={<Checkbox />} label="S" labelPlacement="top" onChange={handleDays}/>
                </FormGroup>
            </FormControl>
        </div>
           
        }
                <FormControl fullWidth sx={{my:1}}>
                    <InputLabel id="demo-simple-select-label">Venue</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={venue}
                    label="Venue"
                    onChange={(e)=>setVenue(e.target.value)}
                    >
                    {venues.length > 0 && venues.map((venue, i)=>{
                        return <MenuItem value={venue._id} key={i}>{venue.venueName}</MenuItem>
                    })}
                    </Select>
                </FormControl>

                <TextField sx={{my:1}} variant='outlined' fullWidth label='Artist Fee' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}} value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Fee' InputProps={{startAdornment: <InputAdornment position="start">£</InputAdornment>}} value={venueFee} onChange={(e)=>setVenueFee(e.target.value)}/>
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Venue Requirements' value={venueReq} onChange={(e)=>setVenueReq(e.target.value)} />
                <TextField sx={{my:1}} variant='outlined' fullWidth multiline rows={2} label='Booking Notes' value={notes} onChange={(e)=>setNotes(e.target.value)} />
                <h3 className='booking-header'>Set Times</h3>
               
                {setTimes.length > 0 ? 
                <div>
                    {setTimes.map((set, i)=>{
                        return <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h4 className='booking-header'>Set {i+1}</h4>
                        <p className='booking-info'>{set.from} - {set.to}</p>
                        <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                      </div>
                    })}
                </div>
                : <p className='booking-info'>No set times added</p>}
                <label>From</label>
                <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
                <label>To</label>
                <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
                <button onClick={addSet} style={{backgroundColor: '#3496d1', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
                
               
                <h3 className='booking-header'>Equipment</h3>
                {equipment.length > 0 ? 
                <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
                    {equipment.map((data) => {
                    let icon;
                    return (
                        <ListItem key={data.key}>
                        <Chip icon={icon} label={data.label} onDelete={handleDelete(data)} />
                        </ListItem>
                    );
                    })}
                                            <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>

                </Paper>
                : <p className='booking-info'>No equipment added</p>}

                <FormControl fullWidth >
                    <FormLabel id="demo-row-radio-buttons-group-label">Payment Method</FormLabel>
                    <RadioGroup value={paymentType} onChange={(e)=>setPaymentType(e.target.value)}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{margin:'10px auto'}}
                    >
                      <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                      <FormControlLabel value="Invoice" control={<Radio />} label="Invoice" />
                  
                    </RadioGroup>
                  </FormControl>
                
                {type === "One-Off" && <Button variant='contained' sx={{backgroundColor:'#3496d1'}} onClick={createOneOff}>Create Event</Button>}
                {type === "Recurring" && <Button variant='contained' sx={{backgroundColor:'#3496d1'}} onClick={createRecurring}>Create Recurring</Button>}
            </Box>

    </main>
  )
}
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));
  