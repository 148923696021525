import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../Firebase'
import { toast } from 'react-toastify'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
      Music Gofer Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function ResetPassword() {

    const [email, setEmail] = useState("")

    const handleReset = (event) => {
        event.preventDefault();

        if(!email){
            toast.error("Please input email")
        } else {
            sendPasswordResetEmail(auth, email)
            .then(() => {
              toast.success("Please check your email")
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              toast.error(errorMessage)
            });
        }
        
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#395076' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleReset} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus value={email} onChange={(e)=>setEmail(e.target.value)}/>
          
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ backgroundColor: '#395076', mt: 3, mb: 2 }}
            >
              Reset
            </Button>
           
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}