import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AdminUI from './AdminUI';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Calendar from './Calendar';
import Artists from './Artists';
import Venues from './Venues';
import Login from './Auth/Login'
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './Auth/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Auth/RequireAuth';
import ResetPassword from './Auth/ResetPassword';
import Bookings from './Bookings';
import ConfirmGig from './ConfirmGig';
import DeclineGig from './DeclineGig';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <ToastContainer position='top-center' />
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/confirm/:id' element={<ConfirmGig />} />
        <Route path='/decline/:id' element={<DeclineGig />} />

        <Route element={<RequireAuth  />} >
          <Route path='/' element={<AdminUI />}>
            <Route index element={<Calendar />} />
            <Route path='bookings' element={<Bookings />} />
            <Route path='artists' element={<Artists />} />
            <Route path='venues' element={<Venues />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
