import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import logo from './images/logo.png'

export default function ConfirmGig() {

    let {id} = useParams();

    useEffect(()=>{
        declineGig()
    },[])

    const declineGig = () =>{
        axios.post(`${process.env.REACT_APP_API}/decline-by-email`, {id})
        .then((res)=>{
            console.log(res.data)
        })
        .catch((e)=>{console.log(e);})
    }

  return (
    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:'4rem'}}>
        <img src={logo} style={{filter:'invert(1)', width:'250px', margin:'2rem'}}/>
        <h3>Successfully declined this gig</h3>
        <p style={{padding:'1rem'}}>If this was an error, please get in touch! We hope to get you more gigs soon.</p>
    </div>
  )
}