import React, { useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { toast} from 'react-toastify'
import axios from 'axios'
import { storage } from './Firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth} from './Auth/AuthContext'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

export default function ArtistView({id, name, setName, email, number, setNumber, youtube, setYoutube, img, setImg, solo, setSolo, duo, setDuo, dj, setDj, drives, setDrives, pa, setPa, decks, setDecks, open, handleClose, getArtists}) {
    
    const { currentUser} = useAuth()
    const handleUpdate = () =>{
       if(!name || !number){
           toast.error("Please add name and number")
       } else {
        axios.post(`${process.env.REACT_APP_API}/artist/update`, {_id: id, payload:{name, email, number, youtube, img, solo, duo, dj, drives, pa, decks}}, {headers: {'AuthToken': currentUser.accessToken}})
        .then(()=>{
            toast.success("Successfully updated")
            handleClose()
            getArtists()
        })
        .catch((e)=>console.log(e))
       }
    }


    const handleDelete = () =>{
        if(window.confirm("Are you sure you want to delete artist?")){
            axios.post(`${process.env.REACT_APP_API}/artist/delete`, {_id: id}, {headers: {'AuthToken': currentUser.accessToken}})
            .then((res)=>{
                toast.success(res.data)
                handleClose()
                getArtists()
            })
            .catch((e)=>console.log(e))
        }
    }

    const imgRef = ref(storage, `${name}/${id}`);
    const uploadImg = (file) =>{
        console.log(imgRef)
        uploadBytes(imgRef, file).then((snapshot) => {

            getDownloadURL(imgRef)
            .then((url) => {
                setImg(url)
            })
            .catch((error) => {
                console.log(error)
            });
        });
    }

  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

                <img src={img} alt={name} style={{width: '100px', height: '100px', margin: '10px auto', borderRadius: '50px', display: 'block', objectFit: 'cover', objectPosition:'center'}}/>
                <input type='file' onChange={(e)=>uploadImg(e.target.files[0])}/>
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Artist Name' value={name} onChange={(e)=>setName(e.target.value)} />
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Email' disabled value={email}  />
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Number' value={number} onChange={(e)=>setNumber(e.target.value)} />
                <TextField sx={{my:1}} variant='outlined' fullWidth label='Youtube' value={youtube} onChange={(e)=>setYoutube(e.target.value)} />
                <FormGroup row sx={{my:1}}>
                    <FormControlLabel control={<Switch checked={drives} onChange={()=> setDrives(!drives)} />} label="Drives"labelPlacement="start" />
                    <FormControlLabel control={<Switch checked={pa} onChange={()=> setPa(!pa)} />} label="PA" labelPlacement="start"/>
                    <FormControlLabel control={<Switch checked={decks} onChange={()=> setDecks(!decks)} />} label="Decks" labelPlacement="start"/>
                </FormGroup>
                <FormGroup row sx={{my:1}}>
                    <FormControlLabel control={<Checkbox checked={solo} onChange={()=> setSolo(!solo)} />} label="Solo" labelPlacement="start"/>
                    <FormControlLabel  control={<Checkbox checked={duo} onChange={()=> setDuo(!duo)}/>} label="Duo" labelPlacement="start"/>
                    <FormControlLabel  control={<Checkbox checked={dj} onChange={()=> setDj(!dj)}/>} label="DJ" labelPlacement="start"/>
                </FormGroup>
                <Button sx={{backgroundColor: '#395076', mr:1}} variant='contained' onClick={handleUpdate}>Update</Button>
                <Button color="error" sx={{ml:1}} variant='contained' 
                onClick={handleDelete}>Delete</Button>

  
        </Box>
      </Modal>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
  };