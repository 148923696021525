import React, { useEffect, useState} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import VenueView from './VenueView';

export default function VenueTable({venues, getVenues}) {

  const [open, setOpen] = useState(false);
  const [id, setId] = useState("")
  const [name, setName] = useState("")
  const [xeroName, setXeroName] = useState("")
  const [address, setAddress] = useState("")
  const [equipment, setEquipment] = useState([])
  const [artistFee, setArtistFee] = useState("")
  const [venueFee, setVenueFee] = useState("")
  const [venueReq, setVenueReq] = useState("")
  const [setTimes, setSetTimes] = useState([])
  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [img, setImg] = useState("")
  const [paymentType, setPaymentType] = useState("")


  const handleOpen = (venue) => {
    const {_id, venueName, xeroName, address, artistFee, venueFee, venueReq, setTimes, equipment, img, contactEmail, contactName, paymentType} = venue;
    setOpen(true)
    setId(_id);setName(venueName);setImg(img);setAddress(address); setEquipment(equipment);setArtistFee(artistFee);setVenueFee(venueFee);setSetTimes(setTimes);
    setContactEmail(contactEmail);setContactName(contactName); setXeroName(xeroName); setVenueReq(venueReq); setPaymentType(paymentType)
   
  }
  const handleClose = () =>{
    setId(""); setName("");setImg("");setAddress(""); setEquipment([]);setArtistFee("");setVenueFee("");setSetTimes([]);
    setContactEmail("");setContactName("");setXeroName("");setVenueReq("")
    setOpen(false);setPaymentType("")
  } ;


    function createData(_id, img, venueName, xeroName, address, setTimes, equipment, contactName, contactEmail, artistFee, venueFee, venueReq, paymentType) {
        return {_id, img, venueName, xeroName, address, setTimes, equipment, contactName, contactEmail, artistFee, venueFee, venueReq, paymentType};
      }
    
    let sorted = venues.sort((a, b) => a.venueName !== b.venueName ? a.venueName < b.venueName ? -1 : 1 : 0)

    let rows = sorted.map((venue)=>{
        const {_id, img, venueName,xeroName, address, setTimes, equipment, contactName, contactEmail, artistFee, venueFee, venueReq, paymentType} = venue;

        return createData(_id, img, venueName, xeroName, address, setTimes, equipment, contactName, contactEmail, artistFee, venueFee, venueReq, paymentType)
    })

  
      
  return (
    <TableContainer component={Paper}>

    <VenueView 
    name={name} setName={setName} address={address} setAddress={setAddress} id={id}
    xeroName={xeroName} setXeroName={setXeroName}
    img={img} setImg={setImg} equipment={equipment} setEquipment={setEquipment}
    artistFee={artistFee} setArtistFee={setArtistFee} venueFee={venueFee} setVenueFee={setVenueFee}
    setTimes={setTimes} setSetTimes={setSetTimes} contactName={contactName} setContactName={setContactName}
    contactEmail={contactEmail} setContactEmail={setContactEmail} venueReq={venueReq} setVenueReq={setVenueReq}
    paymentType={paymentType} setPaymentType={setPaymentType}
    handleClose={handleClose} open={open} getVenues={getVenues}/>

    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="left">Venue</TableCell>
          <TableCell align="left">Address</TableCell>
          <TableCell align="left">Artist Fee</TableCell>
          <TableCell align="left">Venue Fee</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => (
          <TableRow onClick={()=>{handleOpen(row);}}
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            <Avatar alt={row.name} src={row.img} />

            </TableCell>
            <TableCell align="left">{row.venueName}</TableCell>
            <TableCell align="left">{row.address}</TableCell>
            <TableCell align="left">£{row.artistFee}</TableCell>
            <TableCell align="left">£{row.venueFee}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}
